import React from "react"
import Layout from "../components/Layout"

const Error = ({ lang }) => {
  return (
    <Layout lang={lang}>
      <section className="header">
        <h1 className="header__title">404</h1>
        <p className="header__description">Page not found.</p>
      </section>
    </Layout>
  )
}

export default Error
